import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import NavbarRB from './components/navbar/navbar-rb.component';
import ErrorBoundary from './components/error-boundary/error-boundary.component';
import Spinner from './components/spinner/spinner.component';

const Homepage = lazy(() => import('./pages/homepage/hompage.component'));
const ProjectPage = lazy(() => import('./pages/project/project.component'));
const ProjectPreview = lazy(() => import('./pages/project-preview/project-preview.component'));

const App = () => {
    return (
        <div id='page-container'>
            <NavbarRB />
            <Switch>
                <ErrorBoundary>
                    <Suspense fallback={<Spinner />}>
                        <Route exact path='/' component={Homepage} />
                        <Route
                            path='/:categoryName/project/:projectLinkName'
                            component={ProjectPreview}
                        ></Route>
                        <Route
                            exact
                            path='/:categoryName'
                            component={ProjectPage}
                        />
                    </Suspense>
                </ErrorBoundary>
            </Switch>
        </div>
    );
};

export default App;
